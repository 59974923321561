import React from "react"
import styled from "@emotion/styled"
import { linearGradient, primaryColor } from "../components/styles"

const MarkdownContent = styled.div`
  a {
    text-decoration: none;
    position: relative;
  }

  a::after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 70%;
    left: -0.1px;
    right: -0.1px;
    bottom: 0;
    transition: top 0.1s ease-in-out;
    background-color: ${primaryColor};
  }

  a:hover::after {
    top: 0;
  }
  
  h1 {
    display: inline;
    border-radius: 1em 0 1em 0;
    background-image: ${linearGradient}
  }
`

export default ({ post }) => {
  return (
    <MarkdownContent dangerouslySetInnerHTML={{ __html: post.html }}/>
  )
}
