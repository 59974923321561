import React from "react"
import { graphql } from "gatsby"
import styled from "@emotion/styled"
import Layout from "../components/layout"
import MarkdownContent from '../components/markdown-content'

const Content = styled.div`
  margin: 0 auto;
  max-width: 860px;
  padding: 1.45rem 1.0875rem;
`

const isBrowser = () => typeof window !== "undefined"
const isAmber = isBrowser() && window.localStorage.getItem('isAmber');

export default ({ data }) => {
  const post = data.markdownRemark
  return (
    <Layout>
      <Content>
        {isAmber ? <MarkdownContent post={post}/> : 'Dude, go away. You are not Amber.'}
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "DD MMMM, YYYY")
        path
        title
      }
      fields {
        readingTime {
          text
        }
      }
    }
  }
`
